<template>
    <form @submit.prevent="submit" class="otp" @change="validate($event.target.name)">

        <p class="mbn-10">
            {{pathLabel}}
            <b dir="ltr" class="mx-5">
                {{pathMatch === 'EMAIL' ? 'زیر' : pathMatch === 'SMS' ? state.userInfo.mobilenumber : ''}}
            </b>
            را وارد نمایید
        </p>
        <the-input class="mbn-10" :value="data.email" type="text" :align="data.email? 'left' : 'right'"
                   :invalid-text="state.error.email" name="email" placeholder="ایمیل" readonly/>
        <the-input v-model="data.code" :align="data.code? 'left' : 'right'"
                   :invalid-text="state.error.code" name="code" placeholder="کد ارسال شده"/>
        <vue-recaptcha class="d-flex-r-ac-g1-s1" sitekey="6LezA0gcAAAAAGAcICq898569tI4DMHOfiYos_oo"
                       :key="theme" :size="theme"
                       theme="light" language="fa"
                       :loadRecaptchaScript="true"
                       @verify="recaptcha=true" @expired="recaptcha=false" @error="recaptcha=false"/>

        <the-button flat class="gradient-template-linear-pink" type="submit" :title="Object.values(state.error)" @click="submit">
            تایید
        </the-button>
    </form>
</template>

<script>
    import TheInput from "@/components/Tools/TheInput";
    import TheButton from "@/components/Tools/TheButton";
    import VueRecaptcha from "vue-recaptcha";
    import {validate} from "@/lib/reuseableFuncrtions";

    const pathCode = {
        EMAIL: 'کد ارسال شده به ایمیل',
        SMS: 'کد ارسال شده به شماره همراه',
        GOOGLE_AUTHENTICATOR: 'کد تولید شده در برنامه Google Authenticator',
    }

    const errorTemplate = {
        email: {
            required: true,
            regexName: 'email'
        },
        code: {
            required: true,
            minLength: 5,
        }
    }

    export default {
        name: "OTP",
        components: {TheButton, TheInput, VueRecaptcha},
        props: ['pathMatch'],
        data() {
            return {
                data: {
                    email: this.$cookies.get('username') || this.state.userInfo.email,
                    code: '',
                },
            }
        },
        computed: {
            pathLabel() {
                return pathCode[this.pathMatch]
            }
        },
        methods: {
            async submit() {
                if (await this.$validate(this.data, errorTemplate) && this.recaptcha) {
                    this.$setToken('aaaaabbbbbccccc123465789', this.state.userInfo.remember)
                    this.$push({name: 'Profile'})
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
            validate,
        },
        mounted() {
            this.theme = window.screen.width < 340 ? 'compact' : 'normal'
            this.getData()
            if (this.$route.query.ref || localStorage.referralCode) {
                localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
                this.data.referralCode = this.$route.query.ref || localStorage.referralCode
            }
        },
        beforeDestroy() {
            console.log(document.body.lastChild.remove())
        },
    }
</script>

<style scoped>

</style>